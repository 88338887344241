import { createStore } from 'vuex'
export const store = createStore(
{
  state: {
    recordings: [],
    campaigns: [],
    access_token: null
  },
  mutations: {
    setRecordings(state, recordings) {
      state.recordings = recordings;
    },
    setCampaigns(state, campaigns) {
      state.campaigns = campaigns;
    },
    setAccessToken(state, token) {
      state.access_token = token;
    }
  },
  actions: {
    setRecordings(context, recordings) {
      context.commit('setRecordings', recordings);
    },
    setCampaigns(context, campaigns) {
      context.commit('setCampaigns', campaigns);
    },
    setAccessToken(context, token) {
      context.commit('setAccessToken', token);
    }
  },
  getters: {
    getRecordings(state) {
      return state.recordings
    },
    getCampaings(state) {
      return state.campaigns
    },
    getAccessToken(state) {
      return state.access_token
    }
  },
  modules: {
  }
})
