<template>
  <div class="page">
      
      <Audio class="container"/>
  </div>
</template>

<script>
import Audio from './components/Audio.vue'
import { useStore } from 'vuex'
export default {
  name: 'App',
  components: {
    Audio
  },
  setup() {
    const store = useStore();
    const headers = new Headers();
    const token = store.getters.getAccessToken;
    if(token) {
      headers.append('Authorization', 'bearer ' + token);
    }
    
    const recordings = fetch(process.env.VUE_APP_RECORDINGS, { headers: headers } ).then((response) => response.json());
    const campaigns = fetch(process.env.VUE_APP_CAMPAIGNS, { headers: headers }).then((response) => response.json());
    
    Promise.all(
        [ recordings, campaigns ],
      ).then((fetched) => {
        store.dispatch('setRecordings', fetched[0].map((el) => { return {...el, buffer: null, state: 'new', started_at: 0, paused_at: 0, duration: 0, time_left: 0 }} ))
        store.dispatch('setCampaigns', fetched[1].map((el) => { return {...el, buffer: null, state: 'new', started_at: 0, paused_at: 0, duration: 0, time_left: 0 }} ))
        console.log('recordings',store.state.recordings)
        console.log('campaigns',store.state.campaigns)
      }
    )
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

#app {
  font-family: Montserrat Regular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  letter-spacing: .4px;
}

h1 {
  font-family: Playfair Display Regular;
  font-weight: 400;
}

h2 {
  font-family: Playfair Display Regular;
  font-weight: 400;
}

.container {
  max-width: calc(1280px + 5rem);
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
}

.lds-ring {
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #1e53bc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1e53bc transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
