<template>
  <div>
      
      <!-- use the modal component, pass in the prop -->
      <div v-if="showModal">
        <button @click="$emit('closemodal')">ok</button>
        <h1>test</h1>
      </div>

  </div>
</template>

<script>

export default {
  props: {
    showModal: Boolean
  },
  emits: ["closemodal"],
  setup(props) {
    console.log(props.showModal)
    return { };
  },
};
</script>

<style>




</style>