<template>
  <div>
    <div class="menu">
      <img class="logo" src="~@/assets/images/improvers-logo.svg">
      <ul><li class="menu-item"><a href="https://mijn.improvers.nl/">Home</a></li></ul>
    </div>
    <div class="block">
      <div class="block-text">
        <h1 class="header">Voorbeeldgesprekken</h1>
        <p>Hieronder vind je een selectie met interessante gesprekken.</p>
      </div>
      <div class="block-picture">
        <img src="https://improvers-prod-storage-bucket.s3.eu-west-2.amazonaws.com/cire_min_7a2ba2d159.jpg">
      </div>
    </div>
    <!-- <button id="show-modal" @click="showModal = true">Show Modal</button> -->
    <Upload :showModal="showModal" @closemodal="showModal=false"/>
    <div class="cards">
      <template v-for="advertiser in advertisers" :key="advertiser.advertiser">
        <h2>{{advertiser.advertiser}}</h2>
        <div class="card" v-for="(item, index) in advertiser.recordings" @click="rowClick(item, $event)" :key="item.id">
          <div v-show="loading(item.state)" class="lds-ring media-control"><div></div><div></div><div></div><div></div></div>
          <div v-show="paused(item.state)" class="media-control fa-play"></div>
          <div v-show="playing(item.state)" class="media-control fa-pause"></div>
          <div class="index">{{index + 1}}.</div>
          <div class="partner">{{item.first_name}} {{item.last_name}}</div>
          <div class="dash"> - </div>
          <div class="comment">{{item.description}}</div>
          <div class="duration">{{duration(item.duration)}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed} from "vue";
import Upload from './Upload.vue'
import { useStore } from 'vuex'
import { AudioContext } from 'standardized-audio-context';
export default {
  components: {
    Upload
  },
  setup() {
// :rank="0" 
    // const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    const audioCtx = new (AudioContext)();
    audioCtx.onstatechange = function() { console.log(audioCtx.state) }
    const showModal = ref(false);
    const store = useStore();
    const headers = new Headers();
    const token = store.getters.getAccessToken;
    if(token) {
      headers.append('Authorization', 'bearer ' + token);
    }
  
    let source = audioCtx.createBufferSource();
    let current_item = null
    
    let progress_timer = null

    if(progress_timer == null) {
      progress_timer = setInterval(getCurrentTime, 1000)
    }

    // const advertisers = computed(() => {
    //   return store.getters.getRecordings.reduce((acc,cur) => {
    //     const existing = acc.find((item) => {
    //       return item.name === cur.code;
    //     })
    //     console.log('existing', existing)
    //     if (existing) {
    //       existing.recordings.push(cur);
    //     } else {
    //       const recordings = []
    //       recordings.push(cur);
    //       acc.push({name: cur.code, description: cur.description, recordings: recordings})
    //     }
    //     return acc;
    //   },[])
    // })

    const advertisers = computed(() => {
      return store.getters.getRecordings.reduce((acc,cur) => {
      
        const adv_result = store.getters.getCampaings.find((item) => {
          return item.code === cur.code
        })
        console.log('adv_result', adv_result)

        if (adv_result) {

          const existing = acc.find((item) => {
            console.log('adv_advert', adv_result.advertiser)
            return item.advertiser === adv_result.advertiser
          })

          if (existing) {
            existing.recordings.push(cur);
          } else {
            const recordings = []
            recordings.push(cur);
            acc.push({advertiser: adv_result.advertiser, recordings: recordings})
          }

        }

        return acc;
      },[])
    })

    async function rowClick(item) {
      if (current_item && current_item.name != item.name) {
        stop()
      } 
      current_item = item

      if (current_item.state == 'new') {
        current_item.state = 'fetching'
        current_item.buffer = await get_audiobuffer()
        current_item.state = 'fetched'
      }
      play()
      console.log('playing', loading(item.state))
      console.log('paused', loading(item.state))
      console.log('loading', loading(item.state))
    }

    async function get_audiobuffer() {
      const request = new Request(current_item.url);
      return fetch(request, { headers: headers }).then(function(response) {
          return response.arrayBuffer();
      }).then((data) => {
          // return data.slice(1)
          // return data
          // console.log('y',data)
          // let x = new Uint8Array(data).map(x => ~x)
          // console.log('x', x )
          return data
        }
      )
    }

    function deep_copy(src)  {
      let dst = new ArrayBuffer(src.byteLength);
      new Uint8Array(dst).set(new Uint8Array(src));
      return dst;
    }

    function format(time) {   
      // Hours, minutes and seconds
      var mins = ~~((time % 3600) / 60);
      var secs = ~~time % 60;
      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = "";
      ret += "" + mins < 10 ? "0" : "";
      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return "("+ret+")";
    }

    function play_audiobuffer() {
        // let cloned_buffer = current_item.buffer.slice()
        let cloned_buffer = deep_copy(current_item.buffer)
        audioCtx.decodeAudioData(cloned_buffer, (data) => { 
          source = audioCtx.createBufferSource();
          current_item.duration = data.duration
          source.buffer = data
          source.connect(audioCtx.destination);
          source.addEventListener('ended',  itemEnded);
          let paused_at = current_item.paused_at;
          current_item.started_at = audioCtx.currentTime - current_item.paused_at;
          current_item.paused_at = 0
          source.start(0, paused_at);
        })

    }

    function loading(state) {
      return state === 'fetching' || state === 'fetched'
    }

    function duration(duration) {
      if (duration > 0) {
        return format(duration);
      }
      return '';
    }

    function getCurrentTime() {
      if (current_item && current_item.state !== 'ended') {
        if(current_item.paused_at > 0) {
            console.log(current_item.paused_at)
            return current_item.paused_at;
        }
        if(current_item.started_at > 0) {
            console.log(audioCtx.currentTime - current_item.started_at)
            return audioCtx.currentTime - current_item.started_at;
        }
        return 0;
      } else {
        return 0
      }
    }

    function playing(state) {
      return state === 'playing'
    }

    function paused(state) {
      return state === 'new' || state === 'ended' ||  state === 'paused'
    }

    function logModal() {
      console.log('log modal')
    }
    

    function stop() {
      if (source) {          
          source.disconnect();
          source.stop(0);
          source = null;
      }
      current_item.paused_at = 0;
      current_item.started_at = 0;
      current_item.state = 'ended' 
    }

    function pause() {
      source.stop(0);
      current_item.paused_at  = audioCtx.currentTime - current_item.started_at;
      console.log('pause', current_item.paused_at, current_item.started_at)
      current_item.state = 'paused'
    }

    function play() {
        if (current_item.state == 'fetched' || current_item.state == 'ended' || current_item.state == 'paused') {
          current_item.state = 'playing'
          play_audiobuffer()
        } else {
          pause()
        }
    }

    function itemEnded() {
      current_item.state = 'ended'
    }

    return { rowClick, loading, playing, paused ,duration, showModal, logModal, advertisers};
  },
};
</script>

<style>



  .card {
    display: flex;
    align-items: center;

  }

  .block {
    background-color: #1e53bc;
    color: white;
    margin-top: 2.5rem;
    box-sizing: border-box;
    height: 380px;
    margin-bottom: 2.5rem;
    display: flex;
  }

  .block-text {
    width: 50%;
    padding: 5rem;
    box-sizing: border-box;
  }

  .block-picture {
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .block-picture img {
    
    max-width: 100%;
  }


  .card:hover {
    background-color: rgb(245, 244, 244);
  }

  .card div {
    padding: 5px;
  }

.logo {
  max-width: 120px;
}

/* .comment::before {
  content: open-quote;
}
.comment::after {
  content: close-quote;
} */
/* .comment {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
*/


  .media-control {
    width: 40px;
    height: 40px;
  }

  .partner, .index {
    color: #929292;
  }

  .fa-play {
    background-image: url('~@/assets/images/play.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;
  }

  .fa-pause {
    background-image: url('~@/assets/images/pause.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 35%;
    
  }

  .menu {
    display: flex;
    height: 100%;
  }

  ul {
    border-bottom: solid 1px#e6e6e6;
    margin: 0px;
    padding: 0px;
    margin-left: auto;
  }

  li.menu-item {
    font-size: .875rem;
    color: #909399;
    list-style: none;
    position: relative;
    transition: border-color .3s,background-color .3s,color .3s;
    /* box-sizing: border-box; */
    white-space: nowrap;
    height: 100%;
    padding: 0px;
    line-height: 60px;
    margin-left: 1em;
    margin-right: 1em;
    
  }
  li.menu-item:hover {
    color: #303133;
    
  }
  li.menu-item a {
      display:block;
      color: inherit;
      height: 100%;
      text-decoration: none; /* no underline */
  }

</style>